import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import { useNavigate } from "react-router-dom";

import MailOutlineIcon from "@mui/icons-material/MailOutline";

import customerService from "../../img/why_attend/customer-service.webp";
import lightBulb from "../../img/why_attend/light-bulb.webp";
import liveStream from "../../img/why_attend/live-stream.webp";
import note from "../../img/why_attend/note.webp";
import pageImg from "../../img/why_attend/page.webp";
import ribbon from "../../img/why_attend/ribbon.webp";

import { toast } from "react-toastify";

import "./CTAModal.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import settings from "../../misc/constants";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CTAModal({ text, logo, page, type }) {
  const [open, setOpen] = React.useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let data = {
      action: "book_demo",
      name: userName,
      email: userEmail,
      phone: userPhone,
      webinar_name: settings.webinar_date,
      type: `${type}${page}`,
    };

    fetch(`${settings.api_url}/api/book_demo.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          toast.success(data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          sessionStorage.setItem("userEmail", userEmail);

          navigate("/thank-you");
        } else {
          toast.error(data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  return (
    <div>
      <Button
        className="primaryBackground"
        sx={{
          color: "#fff",
          "&:hover": {
            color: "#fff",
          },
        }}
        variant="contained"
        size="large"
        startIcon={logo ? <MailOutlineIcon /> : null}
        onClick={handleClickOpen}
      >
        {text}
      </Button>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <div className="ctamodal">
            <div className="left">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
              >
                Why You Should Attend?
              </Typography>
              <small>
                Join this <strong>online session</strong> and clear your doubts
                about <br />
                digital marketing with us.
              </small>
              <p>Key- points from this session will be:</p>
              <ul>
                <li>
                  <span>
                    <LazyLoadImage
                      className="logo"
                      src={pageImg}
                      alt="page"
                      width="13"
                      style={{ marginRight: "10px" }}
                    />
                    How to make digital marketing your career foundation.
                  </span>
                </li>
                <li>
                  <span>
                    <LazyLoadImage
                      className="logo"
                      src={lightBulb}
                      alt="lightBulb"
                      width="13"
                      style={{ marginRight: "10px" }}
                    />
                    Learn from real-life case studies.
                  </span>
                </li>
                <li>
                  <span>
                    <LazyLoadImage
                      className="logo"
                      src={note}
                      alt=""
                      width="13"
                      style={{ marginRight: "10px" }}
                    />
                    1K+ students got placed in the companies.
                  </span>
                </li>
                <li>
                  <span>
                    <LazyLoadImage
                      className="logo"
                      src={liveStream}
                      alt="liveStream"
                      width="13"
                      style={{ marginRight: "10px" }}
                    />
                    Live exercise: Google trends &amp; Google Analytics.{" "}
                  </span>
                </li>
                <li>
                  <span>
                    <LazyLoadImage
                      className="logo"
                      src={customerService}
                      alt="customerService"
                      width="13"
                      style={{ marginRight: "10px" }}
                    />
                    Live Q&amp;A session.
                  </span>
                </li>
                <li>
                  <LazyLoadImage
                    className="logo"
                    src={ribbon}
                    alt="ribbon"
                    width="13"
                    style={{ marginRight: "10px" }}
                  />
                  <strong>
                    Most awaited and exciting Surprise worth rupees 55k for you!
                  </strong>
                </li>
              </ul>
            </div>
            <div className="right">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
              >
                Get a hardcore career in
                <br />
                <span className="primaryText">Digital Marketing</span>
              </Typography>
              <p
                style={{
                  margin: "10px 0",
                }}
              >
                100% Job Guarantee | Upto 7.5 LPA
              </p>
              <p>
                <strong>Join the Session Now!</strong>
              </p>
              <form onSubmit={handleSubmit}>
                <TextField
                  id="name"
                  variant="standard"
                  placeholder="Name *"
                  inputProps={{
                    style: {
                      padding: "5px",
                      fontSize: "14px",
                    },
                  }}
                  type="text"
                  sx={{ marginBottom: "12px" }}
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                <TextField
                  id="email"
                  variant="standard"
                  placeholder="Email *"
                  type="email"
                  inputProps={{
                    style: {
                      padding: "5px",
                      fontSize: "14px",
                    },
                  }}
                  sx={{ marginBottom: "12px" }}
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
                <TextField
                  id="phone"
                  variant="standard"
                  inputProps={{
                    style: {
                      padding: "5px",
                      fontSize: "14px",
                    },
                  }}
                  placeholder="Phone *"
                  type="text"
                  sx={{ marginBottom: "12px" }}
                  value={userPhone}
                  onChange={(e) => setUserPhone(e.target.value)}
                />
                <Button
                  variant="contained"
                  className="primaryBackground"
                  sx={{ width: "100%" }}
                  type="submit"
                >
                  Book My Seat
                </Button>
              </form>
              <small>
                If already registered, please{" "}
                <a
                  href="https://learn.internshipstudio.com/learn/account/signin"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  log in here
                </a>
                .
              </small>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
