import React, { useState, useEffect } from "react";
import TextTransition, { presets } from "react-text-transition";
import loadable from "@loadable/component";

import { LazyLoadImage } from "react-lazy-load-image-component";

import Aos from "aos";

import College from "../../img/icons/home-college.webp";
import Company from "../../img/icons/home-company.webp";
import Student from "../../img/icons/home-student.webp";
import CTC from "../../img/icons/home-ctc.webp";
import HomeSliderForm from "./HomeSliderForm";

const LandingVideo = loadable(() => import("../LandingVideo/LandingVideo"));

const TEXTS = ["Education.", "Learning.", "Hiring."];

const HomeSlider = ({ page }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 3000);
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <>
      <div className="home-top-section top">
        <div className="left" data-aos="fade-right">
          {page === "home" ? (
            <h1>
              Transforming the way of{" "}
              <span>
                <TextTransition springConfig={presets.wobbly} inline>
                  {TEXTS[index % TEXTS.length]}
                </TextTransition>
              </span>
            </h1>
          ) : (
            <h1
              style={{
                lineHeight: "1.3",
              }}
            >
              Advanced <span className="block">Digital Marketing</span>{" "}
              Certification with 100% Job Guarantee
            </h1>
          )}
          <main className="moto">
            {page === "home" ? (
              <>
                <p>
                  <small>
                    <strong>Learn</strong> from India's top 1% instructors &amp;
                    industry experts.
                  </small>
                </p>
                <p>
                  <small>
                    <strong>Intern</strong> with us &amp; gain real life work
                    experience.
                  </small>
                </p>
                <p>
                  <small>
                    <strong>Lead</strong> in top companies with the help of our
                    placement cell.
                  </small>
                </p>
              </>
            ) : (
              <>
                <p>Be a Job Ready Digital Marketer in 12 weeks.</p>
                <p>100% job guarantee with 68+ partner companies.</p>
              </>
            )}
          </main>
          <div className="icons-info">
            <div>
              <LazyLoadImage
                src={Student}
                alt="Student"
                height="25px"
                width="25px"
              />
              <p>32000+ Students</p>
            </div>
            <div>
              <LazyLoadImage
                src={College}
                alt="College"
                height="25px"
                width="25px"
              />
              <p>
                {page === "home" ? "450+ Colleges" : "10+ Industry Experts"}
              </p>
            </div>
            <div>
              <LazyLoadImage src={CTC} alt="CTC" height="25px" width="25px" />
              <p>7.5 LPA Highest CTC</p>
            </div>
            <div>
              <LazyLoadImage
                src={Company}
                alt="Companies"
                height="25px"
                width="25px"
              />
              <p>68+ Companies</p>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="inner">
            <LandingVideo
              src={
                page === "home"
                  ? "https://www.youtube.com/embed/8T3qe7x1xEU"
                  : "https://www.youtube.com/embed/s_lZjC6g1Qk"
              }
              title="Digital Marketing Course | Internship Studio"
            />
          </div>
        </div>
      </div>
      <HomeSliderForm page={page} />
    </>
  );
};

export default HomeSlider;
